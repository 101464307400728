.input {
  border: 1px solid $grayLight;
  border-radius: 4px;
  height: 44px;
  padding: 10px 12px;
  width: 100%;
  &.error {
    border: 1px solid $red;
  }
  &:disabled {
    background-color: $f8;
    border: 1px solid #ebebeb;
    cursor: not-allowed;
  }
}
.form {
  &__item {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 32px;
    }
    .checkbox__style {
      margin-right: 10px;
    }
    .inputWrap {
      margin-top: 16px;
    }
  }
  &__textarea {
    resize: none;
    height: 90px;
  }

  &__label {
    display: flex;
  }
  &__message {
    //margin-top: 24px;
  }
  &__radio {
    .radio {
      margin-top: 12px;
    }
  }
  &__address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__geo {
    color: $orange;
    font-weight: 500;
    display: flex;
    align-items: center;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    @media (min-width: 990px) {
      &:hover {
        opacity: 0.7;
      }
    }
    @media (max-width: 480px) {
      margin-top: 5px;
    }
    svg {
      margin-right: 6px;
      path {
        fill: $orange;
      }
    }
  }

  &__pricelist {
    margin-top: 24px;
    .inputWrap .input {
      border: 2px solid #c4c4c4;
      max-width: 400px;
    }
  }

  &__price {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  &__policy {
    color: $gray;
    a {
      text-decoration: underline;
      @media (min-width: 990px) {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .error {
    border: 1px solid $red !important;
  }
}

.inputWrap {
  position: relative;
  &__label {
    margin-bottom: 5px;
    color: $gray;
  }
  &__message {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 500;
  }
  &__eye {
    cursor: pointer;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 39px;
    right: 14px;
    path {
      fill: $gray;
    }
  }
}

.inputPhone {
  display: flex;
  .select {
    order: 1;
    height: 100%;
    margin-right: 14px;
  }
  .input {
    order: 2;
  }
}

.remember {
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start
  }
  &__checkbox {
  }

  &__recovery {
    color: $gray;
    border-bottom: 1px solid $gray;
    @media (max-width: 480px) {
      margin-top: 10px;
    }
    @media (min-width: 990px) {
      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }
}